<template>
  <step v-bind="$props">
    <template v-slot:name>Create potential template report</template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'

export default {
  name: 'stepThetradedeskTemplatecreationPotential',
  components: { step },
  mixins: [stepProps]
}
</script>

<style scoped>

</style>
