<template>
  <step v-bind="$props">
    <template v-slot:name>Authorize service account appnexus</template>
    <template v-slot:content>
      <v-card color="lighten-1" class="mb-4" elevation="0">
        <v-text-field label="Service Account Appnexus" small
                      :value="serviceAccount"
                      v-on:input="$emit('change', $event)"
        ></v-text-field>
      </v-card>
      <v-btn color="primary" @click="$emit('authorize', serviceAccount)" :disabled="!serviceAccount">Authorize</v-btn>
    </template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'

export default {
  name: 'stepAuthorizeServiceAccount',
  components: { step },
  mixins: [stepProps],
  model: {
    prop: 'serviceAccount',
    event: 'change'
  },
  props: {
    serviceAccount: String
  }
}
</script>

<style scoped>

</style>
