
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import NewFeatureReferenceModel from '../../models/Keystone_v2/NewFeatureReferenceModel'

@Component({})
export default class NewFeaturesComponent extends Vue {
  @Prop({ required: true }) newFeatures: Array<NewFeatureReferenceModel>
  @Prop({ required: true }) dsp: string

  // LABEL
  getNewSwitchLabel (feature: NewFeatureReferenceModel): string {
    return `Use new ${feature.featureName} feature`
  }
  getLegacySwitchLabel (feature: NewFeatureReferenceModel): string {
    return `Use legacy feature of ${feature.featureName}`
  }

  // UPDATED STATUS SWITCHER
  updatedNFR (feature: NewFeatureReferenceModel) {
    feature.updated = !feature.updated
  }

  get alphaBetaFeatures () {
    if (this.dsp !== 'dbm') {
      return this.newFeatures.filter(x => ['alpha', 'beta'].includes(x[`${this.dsp.toLowerCase()}Status` as keyof NewFeatureReferenceModel] as string))
    } else {
      return this.newFeatures.filter(x => {
        return (
          ['alpha', 'beta'].includes(x.dbmStatus) ||
          ['alpha', 'beta'].includes(x.youtubeStatus)
        )
      })
    }
  }
  get releasesWithExceptionFeatures () {
    if (this.dsp !== 'dbm') {
      return this.newFeatures.filter(x => x[`${this.dsp.toLowerCase()}Status` as keyof NewFeatureReferenceModel] === 'released_with_exception')
    } else {
      return this.newFeatures.filter(x => {
        return (
          x.dbmStatus === 'released_with_exception' ||
          x.youtubeStatus === 'released_with_exception'
        )
      })
    }
  }
}
