<template>
  <v-container className="keystone-tool-container">
    <stepper
      v-bind="stepperAttributes()"
      v-on="stepperEvent()"
    >
      <step-project-creation v-bind="stepAttributes1(1)"></step-project-creation>
      <step-creation-service-account v-bind="stepAttributes1(2)"></step-creation-service-account>
      <step-bucket-shared v-bind="stepAttributes1(3)"></step-bucket-shared>
      <step-bucket-spillway v-bind="stepAttributes1(4)"></step-bucket-spillway>
      <step-bucket-l-l-d v-bind="stepAttributes1(5)"></step-bucket-l-l-d>

      <step-authorize-service-account
        v-model="appnexusServiceAccount"
        v-bind="stepAttributes1(6)"
        @authorize="authorizeAppnexus"
      ></step-authorize-service-account>

      <step-configure-bigquery v-bind="stepAttributes1(7)"></step-configure-bigquery>

      <step-gdpr-full-hash
        v-model="dataType"
        v-bind="stepAttributes1(8)"
        @save="saveDataType"
      ></step-gdpr-full-hash>

      <step-credentials
        v-bind="stepAttributes1(9)"
        v-model="credentialsApi"
        @save="saveCredentials"
      ></step-credentials>

      <step-first-init v-bind="stepAttributes1(10)"
                       @launch="launchFirstInit"
      ></step-first-init>

    </stepper>
  </v-container>
</template>

<script>

import { projectConfig } from '../../../mixins/projectConfig'
import Stepper from './step/stepper'
import StepFirstInit from './step/first_init'
import StepCredentials from './step/credentials'
import StepBucketShared from './step/bucket_shared'
import stepProjectCreation from './step/project_creation'
import StepBucketSpillway from './step/bucket_spillway'
import StepConfigureBigquery from './step/configure_bigquery'
import StepCreationServiceAccount from './step/creation_service_account'
import StepBucketLLD from './step/bucket_lld'
import StepAuthorizeServiceAccount from './step/appnexus/authorizeServiceAccount'
import StepGdprFullHash from './step/appnexus/gdpr_fullhashed'

export default {
  name: 'projectConfigAppnexus',
  mixins: [projectConfig],
  components: {
    StepGdprFullHash,
    StepAuthorizeServiceAccount,
    StepBucketLLD,
    StepCreationServiceAccount,
    StepConfigureBigquery,
    StepBucketSpillway,
    StepFirstInit,
    StepCredentials,
    StepBucketShared,
    Stepper,
    stepProjectCreation
  },
  data: function () {
    return {
      dsp: 'appnexus',
      credentialsApi: {
        username: '',
        password: ''
      },
      dataType: {
        isGDPR: true,
        isFullHashed: false
      },
      appnexusServiceAccount: '',
      stepSettings: [
        { stepName: 'project_creation' },
        { stepName: 'creation_service_account' },
        { stepName: 'bucket_creation_shared' },
        { stepName: 'bucket_creation_spillway' },
        { stepName: 'bucket_creation_lld' },
        { stepName: 'authorize_appnexus_bucket', informationRequired: true },
        { stepName: 'config_bigquery' },
        { stepName: 'data_type', informationRequired: true },
        { stepName: 'credentials_api', informationRequired: true },
        { stepName: 'first_init', informationRequired: true }
      ]
    }
  },
  methods: {
    saveCredentials: function () {
      let stepData = {
        username: this.credentialsApi.username,
        password: this.credentialsApi.password
      }
      this.doStep(this.getStepId('credentials_api'), stepData)
    },
    authorizeAppnexus: function () {
      let stepData = {
        service_account: this.appnexusServiceAccount
      }
      this.doStep(this.getStepId('authorize_appnexus_bucket'), stepData)
    },
    saveDataType: function () {
      let stepData = {
        is_gdpr: this.dataType.isGDPR,
        is_fullhashed: this.dataType.isFullHashed
      }
      this.doStep(this.getStepId('data_type'), stepData)
    },
    launchFirstInit: function () {
      this.doStep(this.getStepId('first_init'))
    }
  }
}
</script>

<style scoped>
.small-stepper-content.v-stepper__content {
  padding-top: 5px;
  padding-bottom: 10px;
}

.text-wrap {
  overflow-wrap: break-word;
}

</style>
