
import { defineComponent } from 'vue'
import step from '@/components/KeystoneComponents/projectConfig/step/step.vue'
import { stepProps } from '@/components/KeystoneComponents/projectConfig/step/stepProps'

export default defineComponent({
  name: 'stepThetradedeskTemplateCreationDataElementReport',
  components: { step },
  mixins: [stepProps]
})
