<template>
  <step v-bind="$props">
    <template v-slot:name>Download configuration</template>
    <template v-slot:content>
      <v-card color="lighten-1" class="mb-4" elevation="0">
        <v-radio-group
          :value="downloadConfiguration.level"
          @change="$emit('change', changeValue('level', $event))"
        >
          <template v-slot:label>
            <div>Choose download level</div>
          </template>
          <v-radio
            label="Partner level"
            value="partner"
          ></v-radio>
          <v-radio
            label="Advertiser level"
            value="advertiser"
          ></v-radio>
        </v-radio-group>

        <v-radio-group
          :value="downloadConfiguration.useAttributedConversions"
          @change="$emit('change', changeValue('useAttributedConversions', $event))"
        >
          <template v-slot:label>
            <div>Use attributed conversions</div>
          </template>
          <v-radio
            label="Yes"
            :value="true"
          ></v-radio>
          <v-radio
            label="No"
            :value="false"
          ></v-radio>
        </v-radio-group>

        <v-radio-group
          :value="downloadConfiguration.useRedsPrivacy"
          @change="$emit('change', changeValue('useRedsPrivacy', $event))"
        >
          <template v-slot:label>
            <div>Use reds privacy</div>
          </template>
          <v-radio
            label="Yes"
            :value="true"
          ></v-radio>
          <v-radio
            label="No"
            :value="false"
          ></v-radio>
        </v-radio-group>
      </v-card>
      <v-btn color="primary" @click="$emit('save', downloadConfiguration)" :disabled="!isValid">Save</v-btn>
    </template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'

export default {
  name: 'stepThetradedeskDownloadLevel',
  components: { step },
  mixins: [stepProps],
  model: {
    prop: 'downloadConfiguration',
    event: 'change'
  },
  methods: {
    changeValue: function (key, event) {
      this.downloadConfiguration[key] = event
      return this.downloadConfiguration
    }
  },
  props: {
    downloadConfiguration: {
      level: '',
      useAttributedConversions: false,
      useRedsPrivacy: false
    }
  },
  computed: {
    isValid: function () {
      return !!this.downloadConfiguration.level
    }
  }
}
</script>

<style scoped>

</style>
