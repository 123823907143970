<template>
  <step v-bind="$props" v-bind:editable="true">
    <template v-slot:name>API credentials</template>
    <template v-slot:content>
      <v-card color="lighten-1" class="mb-4" elevation="0">
        <v-text-field
          label="Username" small
          :value="credentials.username"
          @change="$emit('change', changeValue('username', $event))"
        ></v-text-field>
        <v-text-field
          label="Password" type="password"
          :value="credentials.password"
          @change="$emit('change', changeValue('password', $event))"
        ></v-text-field>
      </v-card>

      <v-btn color="primary" @click="$emit('save', credentials)" :disabled="!credentialsIsValid()">Save</v-btn>
    </template>
  </step>
</template>

<script>
import step from './step'
import { stepProps } from './stepProps'

export default {
  name: 'stepCredentials',
  components: { step },
  mixins: [stepProps],
  model: {
    prop: 'credentials',
    event: 'change'
  },
  props: {
    credentials: Object
  },
  methods: {
    changeValue: function (key, event) {
      this.credentials[key] = event
      return this.credentials
    },
    credentialsIsValid: function () {
      if (!!this.credentials.username && !!this.credentials.password) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
