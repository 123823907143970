<template>
  <step v-bind="$props">
    <template v-slot:name>LLD Version</template>
    <template v-slot:content>
      <v-progress-circular
        indeterminate
        color="primary" v-if="loading"
      ></v-progress-circular>
      <v-card color="lighten-1" class="mb-4" elevation="0" v-else-if="error">
        <v-btn color="primary" @click="$emit('reload')">Reload</v-btn>

      </v-card>
      <v-card color="lighten-1" class="mb-4" elevation="0" v-else>
        <v-alert
          border="bottom"
          colored-border
          type="warning"
          elevation="2"
          value="1"
          v-if="!versions.detected"
        >
          We are not able to detect the available versions. All versions are listed
        </v-alert>
        <v-radio-group
          :value="lldVersion"
          @change="$emit('change', $event)"

        >
          <template v-slot:label>
            <div>Choose LLD version</div>
          </template>
          <v-radio v-for="version in versions.versions" :key="version"
            :label="version"
            :value="version"
          ></v-radio>
        </v-radio-group>
        <v-btn color="primary" @click="$emit('save', lldVersion)" :disabled="!isValid">Save</v-btn>
        <v-btn color="primary" @click="$emit('reload')">Reload</v-btn>
      </v-card>
    </template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'

export default {
  name: 'stepThetradedeskLldVersion',
  components: { step },
  mixins: [stepProps],
  model: {
    prop: 'lldVersion',
    event: 'change'
  },
  props: {
    versions: Object,
    loading: Boolean,
    lldVersion: String
  },
  computed: {
    isValid: function () {
      return !!this.lldVersion
    }
  }
}
</script>

<style scoped>

</style>
