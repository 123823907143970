<template>
  <v-container className="keystone-tool-container">
    <stepper
      v-bind="stepperAttributes()"
      v-on="stepperEvent()"
    >
      <step-project-creation v-bind="stepAttributes1(1)"></step-project-creation>
      <step-creation-service-account v-bind="stepAttributes1(2)"></step-creation-service-account>
      <step-bucket-shared v-bind="stepAttributes1(3)"></step-bucket-shared>
      <step-bucket-spillway v-bind="stepAttributes1(4)"></step-bucket-spillway>
      <step-configure-bigquery v-bind="stepAttributes1(5)"></step-configure-bigquery>

      <step-thetradedesk-bucket-access
        v-bind="stepAttributes1(6)"
        v-model="bucketAccess"
        @save="saveBucketAccess"
      ></step-thetradedesk-bucket-access>

      <step-thetradedesk-download-level
        v-bind="stepAttributes1(7)"
        v-model="downloadConfiguration"
        @save="saveDownloadConfiguration"
      ></step-thetradedesk-download-level>

      <step-thetradedesk-lld-version
        v-bind="stepAttributes1(8)"
        v-model="selectedLldVersion"
        :versions="lldVersions"
        :loading="loading.lldVersions"
        @save="saveLldVersion"
        @reload="loadLldVersions"
      ></step-thetradedesk-lld-version>

      <step-credentials
        v-bind="stepAttributes1(9)"
        v-model="credentialsApi"
        @save="saveCredentials"
      ></step-credentials>

      <step-website-credentials
        v-bind="stepAttributes1(10)"
        v-model="credentialsWebsite"
        @save="saveWebsiteCredentials"
      ></step-website-credentials>

      <step-thetradedesk-templatecreation-conversion
        v-bind="stepAttributes1(11)"
        v-model="withFiveConversion"
        @save="createTemplateConversion"
      ></step-thetradedesk-templatecreation-conversion>
      <step-thetradedesk-templatecreation-fees v-bind="stepAttributes1(12)"></step-thetradedesk-templatecreation-fees>
      <step-thetradedesk-templatecreation-potential v-bind="stepAttributes1(13)"></step-thetradedesk-templatecreation-potential>
      <step-thetradedesk-template-creation-data-element-report v-bind="stepAttributes1(14)"></step-thetradedesk-template-creation-data-element-report>

      <step-first-init v-bind="stepAttributes1(15)"
                       @launch="launchFirstInit"
      ></step-first-init>

    </stepper>
  </v-container>
</template>

<script>
import { projectConfig } from '../../../mixins/projectConfig'
import Stepper from './step/stepper'
import StepFirstInit from './step/first_init'
import StepCredentials from './step/credentials'
import StepBucketShared from './step/bucket_shared'
import stepProjectCreation from './step/project_creation'
import StepBucketSpillway from './step/bucket_spillway'
import StepConfigureBigquery from './step/configure_bigquery'
import StepThetradedeskBucketAccess from './step/ttd/bucket_access'
import StepThetradedeskDownloadLevel from './step/ttd/download_level'
import StepThetradedeskLldVersion from './step/ttd/lld_version'
import StepCreationServiceAccount from './step/creation_service_account'
import StepWebsiteCredentials from '@/components/KeystoneComponents/projectConfig/step/ttd/website_credentials.vue'
import StepThetradedeskTemplatecreationConversion
  from '@/components/KeystoneComponents/projectConfig/step/ttd/template_creation_conversion.vue'
import StepThetradedeskTemplatecreationFees
  from '@/components/KeystoneComponents/projectConfig/step/ttd/template_creation_fees.vue'
import StepThetradedeskTemplatecreationPotential
  from '@/components/KeystoneComponents/projectConfig/step/ttd/template_creation_potential.vue'
import StepThetradedeskTemplateCreationDataElementReport
  from '@/components/KeystoneComponents/projectConfig/step/ttd/template_creation_data_element_report.vue'

export default {
  name: 'projectConfigTheTradeDesk',
  mixins: [projectConfig],
  components: {
    StepThetradedeskTemplatecreationPotential,
    StepThetradedeskTemplatecreationFees,
    StepThetradedeskTemplatecreationConversion,
    StepWebsiteCredentials,
    StepCreationServiceAccount,
    StepThetradedeskLldVersion,
    StepThetradedeskDownloadLevel,
    StepThetradedeskBucketAccess,
    StepConfigureBigquery,
    StepBucketSpillway,
    StepFirstInit,
    StepCredentials,
    StepBucketShared,
    Stepper,
    stepProjectCreation,
    StepThetradedeskTemplateCreationDataElementReport
  },

  data: function () {
    return {
      loading: {
        lldVersions: true
      },
      dsp: 'thetradedesk',
      credentialsApi: {
        username: '',
        password: ''
      },
      credentialsWebsite: {
        sameAsApi: true,
        username: '',
        password: '',
        mfa: ''
      },
      bucketAccess: {
        useScibidsFolder: false,
        accessKey: '',
        secretKey: ''
      },
      downloadConfiguration: {
        level: '',
        useAttributedConversions: false,
        useRedsPrivacy: false
      },
      selectedLldVersion: '',
      lldVersions: {
        versions: [],
        detected: true
      },
      withFiveConversion: false,
      stepSettings: [
        { stepName: 'project_creation' },
        { stepName: 'creation_service_account' },
        { stepName: 'bucket_creation_shared' },
        { stepName: 'bucket_creation_spillway' },
        { stepName: 'config_bigquery' },
        { stepName: 'thetradedesk_bucket_access', informationRequired: true },
        { stepName: 'thetradedesk_download_level', informationRequired: true },
        { stepName: 'thetradedesk_lld_version', informationRequired: true },
        { stepName: 'credentials_api', informationRequired: true },
        { stepName: 'thetradedesk_credentials_website', informationRequired: true },
        { stepName: 'thetradedesk_template_creation_conversion', informationRequired: true },
        { stepName: 'thetradedesk_template_creation_fees' },
        { stepName: 'thetradedesk_template_creation_potential' },
        { stepName: 'thetradedesk_template_creation_data_element_report' },
        { stepName: 'first_init', informationRequired: true }
      ]
    }
  },
  methods: {
    saveCredentials: function () {
      let stepData = {
        username: this.credentialsApi.username,
        password: this.credentialsApi.password
      }
      this.doStep(this.getStepId('credentials_api'), stepData)
    },
    saveWebsiteCredentials: function () {
      let stepData = {
        same_as_api: this.credentialsWebsite.sameAsApi,
        username: this.credentialsWebsite.username,
        password: this.credentialsWebsite.password,
        mfa: this.credentialsWebsite.mfa
      }
      this.doStep(this.getStepId('thetradedesk_credentials_website'), stepData)
    },
    saveBucketAccess: function () {
      let stepData = {
        use_scibids_folder: this.bucketAccess.useScibidsFolder,
        access_key: this.bucketAccess.accessKey,
        secret_key: this.bucketAccess.secretKey
      }
      this.doStep(this.getStepId('thetradedesk_bucket_access'), stepData)
    },
    saveDownloadConfiguration: function () {
      let stepData = {
        download_level: this.downloadConfiguration.level,
        use_attributed_conversions: this.downloadConfiguration.useAttributedConversions,
        use_reds_privacy: this.downloadConfiguration.useRedsPrivacy
      }
      this.doStep(this.getStepId('thetradedesk_download_level'), stepData)
    },
    saveLldVersion: function () {
      let stepData = {
        lld_version: this.selectedLldVersion
      }
      this.doStep(this.getStepId('thetradedesk_lld_version'), stepData)
    },
    launchFirstInit: function () {
      this.doStep(this.getStepId('first_init'))
    },
    loadLldVersions: async function () {
      this.loading.lldVersions = true
      this.errors['thetradedesk_lld_version'] = ''
      let response = await this.$apiCaller.getTheTradeDeskLldVersions(this.client_id)
      this.loading.lldVersions = false
      if (response.status === 200) {
        this.errors['thetradedesk_lld_version'] = ''
        this.lldVersions = response.data
      } else {
        this.errors['thetradedesk_lld_version'] = response.response.data.errors
      }
    },
    createTemplateConversion: function () {
      let stepData = {
        with_5_conv: this.withFiveConversion
      }
      this.doStep(this.getStepId('thetradedesk_template_creation_conversion'), stepData)
    }
  },
  watch: {
    currentStep: {
      async handler (newValue, oldValue) {
        if (newValue === this.getStepId('thetradedesk_lld_version')) {
          await this.loadLldVersions()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
