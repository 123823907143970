<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2"
      primary-title
    >
      Action
    </v-card-title>

    <v-card-text v-if="displayCard" class="d-inline-block pa-4">
      <v-row no-gutters row>
        <v-col cols="2">
          <v-avatar class="dsp-keystone-table" :size="20">
            <img
              v-if="dsp"
              :src="require('../../../assets/' + dsp + '.png')"
              :alt="dsp"
              :title="dsp"
            />
          </v-avatar>

        </v-col>
        <v-col cols="5" class="action-info text-body-1">
          External Id : {{$commonUtils.sliceIfTooLength(clientId, MAX_LENGTH_INFO)}}
        </v-col>
        <v-col cols="5" class="action-info text-body-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                Seat Name : {{$commonUtils.sliceIfTooLength(clientName, MAX_LENGTH_INFO)}}
              </span>
            </template>
            <span>{{ clientName }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row wrap no-gutters class="mt-0">
        <v-list two-line v-if="displayList">
          <template v-for="(item, index) in actionsItem">
            <v-subheader
              v-if="item.header"
              :key="item.header"
            >
              {{ item.header }}
            </v-subheader>

            <v-divider
              v-else-if="item.divider"
              :key="index"
              :inset="item.inset"
            ></v-divider>

            <v-list-item
              v-else
              :key="item.title"
            >
              <v-list-item-avatar class="avatar-action-keystone">
                <v-btn
                  @click="processJob(item.job)"
                  :loading="isProcessing"
                  fab
                  small
                  color="primary"
                  :class="item.className()"
                  :disabled="item.disabled()"
                >
                  <v-icon>
                    {{item.icon()}}
                  </v-icon>
                </v-btn>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="title-font-size">
                  {{ item.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-body-1">
                  <span v-html="item.subtitle()"></span>
                  <v-menu offset-y  v-if="historyRemoteJob.length > 0">
                    <template v-slot:activator="{ on }">
                      <span
                        class="show-history"
                        v-on="on"
                      >
                        Click for see history
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(historyItem, index) in historyRemoteJob"
                        :key="index"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>Inserted : {{$commonUtils.formatDate(historyItem.inserted_at)}} | </span>
                            <span v-if="historyItem.started_at">Started : {{$commonUtils.formatDate(historyItem.started_at)}} | </span>
                            <span v-if="historyItem.finished_at">Finished : {{$commonUtils.formatDate(historyItem.finished_at)}} | </span>
                            <span class='text--primary'>Status</span> &mdash; {{getRemoteJobStatus(historyItem)}}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-alert class="alert-dsp-settings" :value="!displayList" type="warning" icon="warning">
          DSP seat not set-up in our DB
        </v-alert>
      </v-row>

    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions v-show="displayCard">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="closeDialog"
      >
        Close
      </v-btn>
    </v-card-actions>
    <v-card-text v-show="!displayCard" class="process-loading">
      <div>
      </div>
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      >
      </v-progress-circular>
    </v-card-text>
  </v-card>

</template>

<script>
import { DSPSettingsModelFactory } from '@/models/Keystone/DSPSettingsModel'
import MemberModel from '@/models/Keystone_v2/MemberModel'

export default {
  name: 'KeyStoneActionV2',
  data: function () {
    return {
      remoteJob: null,
      historyRemoteJob: [],
      /**
       * @type {DSPSettingsModel}
       */
      dspSettings: null,
      isLoading: false,
      isProcessing: false,
      MAX_LENGTH_INFO: 12,
      actionsItem: [
        { header: 'Action' },
        {
          job: 'datainput',
          title: 'Direct Data Input',
          className: () => this.classDataInput,
          icon: () => this.iconBtnDataInput,
          disabled: () => this.isDisabledDataInput,
          subtitle: () => `<span class='text--primary'>Status</span> &mdash; ${this.dataInputStatus}`
        },
        { divider: true, inset: true }
      ]
    }
  },
  props: {
    member: {
      type: MemberModel
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },
    async callDspConfig () {
      this.isLoading = true
      const resultConfig = await this.$apiCaller.getDspConfigs(this.dsp, { id: this.clientId })
      this.isLoading = false

      if (!this.$apiCaller.isResponseError(resultConfig)) {
        if (resultConfig.data.length > 0) {
          const dspSettingsFactory = new DSPSettingsModelFactory()
          this.dspSettings = dspSettingsFactory.getDspSettingsModel(this.dsp, resultConfig.data[0])
        } else {
          this.dspSettings = null
        }
      } else {
        console.warn('Error call dsp config')
      }
    },
    async callRemote (clientId, jobName) {
      const data = {
        client_id: clientId,
        name: jobName
      }
      this.isLoading = true
      const result = await this.$apiCaller.getRemoteJobs(data)
      this.isLoading = false

      if (!this.$apiCaller.isResponseError(result)) {
        this.remoteJob = result.data[0]
        this.historyRemoteJob = result.data
      } else {
        this.$store.commit('setErrorMessageWithResponse', result)
      }
    },
    async processJob (jobName) {
      const dataForApi = {

        data: {
          name: jobName,
          parameter: {
            dsp: this.dsp,
            client_id: this.clientId
          }
        }
      }
      this.isProcessing = true
      const result = await this.$apiCaller.postRemoteJobs(dataForApi)
      this.isProcessing = false

      if (!this.$apiCaller.isResponseError(result)) {
        console.warn('Process success')
        await this.callRemote(this.clientId, 'datainput')
      } else {
        this.$store.commit('setErrorMessageWithResponse', result)
        console.warn('Process error')
      }
    },
    getRemoteJobStatus (remoteJob) {
      if (remoteJob && remoteJob.executed) {
        return 'Executed'
      } else if (remoteJob && remoteJob.running) {
        return 'Running'
      } else if (remoteJob) {
        return 'Pending'
      }
      return 'Not launch'
    }
  },
  computed: {
    displayCard () {
      return !this.isLoading && this.clientId && this.dsp
    },
    displayList () {
      return this.dspSettings !== undefined && this.dspSettings !== null && this.dspSettings.isActive
    },
    dataInputStatus () {
      return this.getRemoteJobStatus(this.remoteJob)
    },
    isDisabledDataInput () {
      return ['Running', 'Pending'].indexOf(this.dataInputStatus) !== -1
    },
    iconBtnDataInput () {
      return this.isDisabledDataInput ? 'schedule' : 'launch'
    },
    classDataInput () {
      if (this.dataInputStatus === 'Executed') {
        return 'executed-btn'
      } else if (this.dataInputStatus === 'Running') {
        return 'running-btn'
      } else if (this.dataInputStatus === 'Pending') {
        return 'pending-btn'
      } else {
        return 'normal-btn'
      }
    },
    itemKey () {
      return `${this.clientId}_${this.dsp}`
    },
    dsp () {
      return this.member ? this.member.dsp : null
    },
    clientId () {
      return this.member ? this.member.externalId : null
    },
    clientName () {
      return this.member ? this.member.name : null
    }
  },
  watch: {
    'itemKey': {
      immediate: true,
      deep: true,
      async handler (newValue) {
        if (this.isOpen && this.clientId && this.dsp) {
          await this.callDspConfig()
          await this.callRemote(this.clientId, 'datainput')
        }
      }
    }
  }
}
</script>

<style scoped>
.title-font-size {
  font-size: 16px;
}
.executed-btn {
  background: black;
}

.running-btn {
  background: #00acc1;
}

.pending-btn {
  background: orange;
}

.normal-btn {

}

.alert-dsp-settings {
  width: 100%;
  margin-top: 2em;
}

.show-history {
  font-style: italic;
  cursor: pointer;
  padding-left: 35px;
  margin-right: 4px;
}
</style>

<style>
.v-list__tile__avatar.avatar-action-keystone {
  margin-top: 0;
}
</style>
