<template>
  <component
    :is="componentSelected"
    :client_id="clientId"
  ></component>
</template>

<script>

import projectConfigAppnexus from './projectConfigAppnexus'
import projectConfigDBM from './projectConfigDBM'
import projectConfigTheTradeDesk from './projectConfigTheTradeDesk'
import projectConfigMediamath from './projectConfigMediamath'
import projectConfigBeeswax from './projectConfigBeeswax'

/**
 * adaptation of project config for keystoneV2.
 * replace props settings {CRMSettingsModel} by 2 props clientId and dsp
 */
export default {
  name: 'projectConfigKeystoneV2',
  props: {
    clientId: {
      type: String
    },
    dsp: {
      type: String
    }
  },
  computed: {
    componentSelected: function () {
      console.log(this.dsp)
      switch (this.dsp) {
        case 'appnexus':
          return projectConfigAppnexus
        case 'dbm':
          return projectConfigDBM
        case 'thetradedesk':
          return projectConfigTheTradeDesk
        case 'mediamath':
          return projectConfigMediamath
        case 'beeswax':
          return projectConfigBeeswax
      }
      return null
    }
  },
  methods: {
    closeDialog: function () {
      this.$emit('close')
    }
  }
}
</script>
