<template>
  <step v-bind="$props">
    <template v-slot:name>Data GDPR/FullHashed</template>
    <template v-slot:content>
      <v-card color="lighten-1" class="mb-4" elevation="0">
        <v-checkbox
          :input-value="dataType.isGDPR"
          @change="$emit('change', changeValue('isGDPR', $event))"
          label="is gdpr"
        ></v-checkbox>
        <v-checkbox
          :input-value="dataType.isFullHashed"
          @change="$emit('change', changeValue('isFullHashed', $event))"
          label="is fullhashed"
        ></v-checkbox>
      </v-card>
      <v-btn color="primary" @click="$emit('save', dataType)">Save</v-btn>
    </template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'

export default {
  name: 'stepGdprFullHash',
  components: { step },
  mixins: [stepProps],
  model: {
    prop: 'dataType',
    event: 'change'
  },
  props: {
    dataType: Object
  },
  methods: {
    changeValue: function (key, event) {
      this.dataType[key] = event === true
      return this.dataType
    }
  }
}
</script>

<style scoped>

</style>
