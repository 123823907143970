<template>
  <v-container className="keystone-tool-container">
    <stepper
      v-bind="stepperAttributes()"
      v-on="stepperEvent()"
    >
      <step-project-creation v-bind="stepAttributes1(1)"></step-project-creation>
      <step-creation-service-account v-bind="stepAttributes1(2)"></step-creation-service-account>
      <step-bucket-shared v-bind="stepAttributes1(3)"></step-bucket-shared>
      <step-bucket-spillway v-bind="stepAttributes1(4)"></step-bucket-spillway>
      <step-configure-bigquery v-bind="stepAttributes1(5)"></step-configure-bigquery>

      <step-beeswax-bucket-s3 v-bind="stepAttributes1(6)"></step-beeswax-bucket-s3>

      <step-credentials
        v-bind="stepAttributes1(7)"
        v-model="credentialsApi"
        @save="saveCredentials"
      ></step-credentials>

      <step-first-init v-bind="stepAttributes1(8)"
                       @launch="launchFirstInit"
      ></step-first-init>
    </stepper>
  </v-container>
</template>

<script>
import { projectConfig } from '../../../mixins/projectConfig'
import Stepper from './step/stepper'
import StepFirstInit from './step/first_init'
import StepCredentials from './step/credentials'
import StepBucketShared from './step/bucket_shared'
import stepProjectCreation from './step/project_creation'
import StepBucketSpillway from './step/bucket_spillway'
import StepConfigureBigquery from './step/configure_bigquery'
import StepBeeswaxBucketS3 from './step/beeswax/bucket_s3'
import StepCreationServiceAccount from './step/creation_service_account'

export default {
  name: 'projectConfigBeeswax',
  mixins: [projectConfig],
  components: {
    StepCreationServiceAccount,
    StepBeeswaxBucketS3,
    StepConfigureBigquery,
    StepBucketSpillway,
    StepFirstInit,
    StepCredentials,
    StepBucketShared,
    Stepper,
    stepProjectCreation
  },

  data: function () {
    return {
      loading: {
        lldVersions: true
      },
      dsp: 'beeswax',
      credentialsApi: {
        username: '',
        password: ''
      },
      downloadLevel: '',
      stepSettings: [
        { stepName: 'project_creation' },
        { stepName: 'creation_service_account' },
        { stepName: 'bucket_creation_shared' },
        { stepName: 'bucket_creation_spillway' },
        { stepName: 'config_bigquery' },
        { stepName: 'beeswax_bucket_creation_s3' },
        { stepName: 'credentials_api', informationRequired: true },
        { stepName: 'first_init', informationRequired: true }
      ]
    }
  },
  methods: {
    saveCredentials: function () {
      let stepData = {
        username: this.credentialsApi.username,
        password: this.credentialsApi.password
      }
      this.doStep(this.getStepId('credentials_api'), stepData)
    },
    launchFirstInit: function () {
      this.doStep(this.getStepId('first_init'))
    }
  }
}
</script>

<style scoped>

</style>
