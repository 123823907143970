<template>
  <step v-bind="$props" v-bind:editable="true">
    <template v-slot:name>Website credentials</template>
    <template v-slot:content>
      <v-card color="lighten-1" class="mb-4" elevation="0">
        <v-radio-group
          :value="credentials.sameAsApi"
          @change="$emit('change', changeValue('sameAsApi', $event))"
        >
          <template v-slot:label>
            <div>Same as API?</div>
          </template>
          <v-radio
            label="Yes"
            :value="true"
          ></v-radio>
          <v-radio
            label="No"
            :value="false"
          ></v-radio>
        </v-radio-group>

        <template v-if="!credentials.sameAsApi">
          <v-text-field
            label="Username" small
            :value="credentials.username"
            @change="$emit('change', changeValue('username', $event))"
          ></v-text-field>
          <v-text-field
            label="Password" type="password"
            :value="credentials.password"
            @change="$emit('change', changeValue('password', $event))"
          ></v-text-field>

          <v-switch
            v-model="withMfa"
            label="With mfa"
            :value="true"
            @change="credentials['mfa']=''"
          ></v-switch>
          <v-text-field
            label="MFA code"
            :value="credentials.mfa"
            @change="$emit('change', changeValue('mfa', $event))"
            v-if="withMfa"
          ></v-text-field>
        </template>
      </v-card>

      <v-btn color="primary" @click="$emit('save', credentials)" :disabled="!credentialsIsValid()">Save</v-btn>
    </template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'

export default {
  name: 'stepWebsiteCredentials',
  components: { step },
  mixins: [stepProps],
  model: {
    prop: 'credentials',
    event: 'change'
  },
  data: function () {
    return {
      withMfa: false
    }
  },
  props: {
    credentials: {
      sameAsApi: true,
      username: '',
      password: '',
      mfa: ''
    }
  },
  methods: {
    changeValue: function (key, event) {
      this.credentials[key] = event
      return this.credentials
    },
    credentialsIsValid: function () {
      if (this.credentials.sameAsApi) {
        return true
      } else if (this.withMfa && !this.credentials.mfa) {
        return false
      } else if (!!this.credentials.username && !!this.credentials.password) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
