<template>
  <step v-bind="$props">
    <template v-slot:name>S3 access</template>
    <template v-slot:content>
      <v-card color="lighten-1" class="mb-4" elevation="0">
        <v-switch
          :input-value="bucketAccess.useScibidsFolder"
          @change="$emit('change', changeValue('useScibidsFolder', $event))"
          :label="bucketAccess.useScibidsFolder?'use Scibids folder':'Old method access'"
        ></v-switch>

        <template v-if="!bucketAccess.useScibidsFolder">
          <v-text-field small label="Access Key"
                        :value="bucketAccess.accessKey"
                        @change="$emit('change', changeValue('accessKey', $event))"
          ></v-text-field>
          <v-text-field label="Secret Key"
                        :value="bucketAccess.secretKey"
                        @change="$emit('change', changeValue('secretKey', $event))"
          ></v-text-field>
        </template>
      </v-card>
      <v-btn color="primary" @click="$emit('save', bucketAccess)" :disabled="!isValid">Save</v-btn>
    </template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'

export default {
  name: 'stepThetradedeskBucketAccess',
  components: { step },
  mixins: [stepProps],
  model: {
    prop: 'bucketAccess',
    event: 'change'
  },
  props: {
    bucketAccess: Object
  },
  methods: {
    changeValue: function (key, event) {
      if (key === 'useScibidsFolder') {
        this.bucketAccess[key] = event === true
      } else {
        this.bucketAccess[key] = event
      }
      return this.bucketAccess
    }
  },
  computed: {
    isValid: function () {
      if (this.bucketAccess.useScibidsFolder) {
        return true
      }
      if (!!this.bucketAccess.accessKey && !!this.bucketAccess.secretKey) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
