<template>
  <step v-bind="$props">
    <template v-slot:name>Create conversion template report</template>
    <template v-slot:content>
      <v-card color="lighten-1" class="mb-4" elevation="0">
        <v-radio-group
          :value="withFiveConversion"
          @change="$emit('change', $event)"
        >
          <template v-slot:label>
            <div>Number of column conversion?</div>
          </template>
          <v-radio
            label="With 5 columns conversion"
            :value="true"
          ></v-radio>
          <v-radio
            label="With 10 columns conversion"
            :value="false"
          ></v-radio>
        </v-radio-group>
      </v-card>
      <v-btn color="primary" @click="$emit('save', withFiveConversion)">Create</v-btn>
    </template>
  </step>
</template>

<script>
import step from '../step'
import { stepProps } from '../stepProps'

export default {
  name: 'stepThetradedeskTemplatecreationConversion',
  components: { step },
  mixins: [stepProps],
  model: {
    prop: 'withFiveConversion',
    event: 'change'
  },
  props: {
    withFiveConversion: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
