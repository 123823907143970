<template>
  <v-dialog eager v-model="dialog" width="1200px" persistent>
    <BaseEditCardEntity
      :entity="currentEntity"
      :tab-items="editCardItems"
      entity-type="member"
      :is-edit="isEditForm"
      v-on:close="closeNewMember"
      :save-item="saveMember"
      :key="currentEntity.id"
      :is-loading="loadingMember"
      no-border-top
      no-elevation
      :active-tab.sync="activeTab"
      :disabled-save="processingMember"
    >
      <template v-slot:title="{ entity }">
        <v-avatar tile :size="25">
          <img
            v-if="entity && entity.member && entity.member.dsp"
            :src="require('../../../assets/' + entity.member.dsp + '.png')"
            :alt="entity.member.dsp"
            :title="entity.member.dsp"
          />
        </v-avatar>
        <span class="pl-2" v-if="entity && entity.member && entity.member.name && entity.member.externalId">
          {{`${isEditForm ? 'Edit' : 'New'} item | member ${entity.member.name} (${entity.member.externalId})`}}
        </span>
      </template>
      <template v-slot:SETTINGS="{ entity }">
        <v-row no-gutters wrap v-if="currentEntity && currentEntity.member && currentEntity.member.externalId && !dspSettingsLoading && !newFeaturesLoading">
          <SettingsTabsKeystoneV2
            :memberSetting="currentEntity"
            :dspSettings="dspSettings"
            :isLoading="loadingMember"
            :newFeatures="newFeatures"
          >
          </SettingsTabsKeystoneV2>
        </v-row>
      </template>
      <template v-slot:PROJECT_CONFIG="{ entity }">
        <v-row wrap v-if="currentEntity && currentEntity.member && currentEntity.member.externalId">
          <projectConfigKeystoneV2
            :client-id="currentEntity.member.externalId"
            :dsp="currentEntity.member.dsp"
          >
          </projectConfigKeystoneV2>
        </v-row>
      </template>
      <template v-slot:HISTORY="{ entity }">
        <HistoryLogsComponent
          v-if="currentEntity.id != null"
          :entityId="currentEntity.id"
          entityType="keystone_settings_member"
        ></HistoryLogsComponent>
      </template>
    </BaseEditCardEntity>
  </v-dialog>

</template>

<script>
import BaseEditCardEntity from '@/components/KeystoneV2/BaseEditCardEntity'
import { entityFormMixin } from '@/mixins/entityFormMixin'
import { rulesMixin } from '@/mixins/rulesMixin'
import MemberSettingModel from '@/models/Keystone_v2/MemberSettingModel'
import projectConfigKeystoneV2 from '@/components/KeystoneComponents/projectConfig/projectConfigKeystoneV2'
import SettingsTabsKeystoneV2 from '@/components/KeystoneV2/SettingsTabsKeystoneV2'
import { getDspSettingsMixin } from '@/mixins/getDspSettingsMixin'
import store from '../../../../store'
import { common } from '../../../../store/modules/common'
import NewFeatureReferenceModel from '../../../models/Keystone_v2/NewFeatureReferenceModel'
import HistoryLogsComponent from '@/components/TableComponents/Form/FormFragments/HistoryLogs'

if (!store.state.common) store.registerModule('common', common)

export default {
  name: 'MemberForm',
  components: {
    SettingsTabsKeystoneV2,
    BaseEditCardEntity,
    projectConfigKeystoneV2,
    HistoryLogsComponent
  },
  mixins: [
    entityFormMixin,
    rulesMixin,
    getDspSettingsMixin
  ],
  data: function () {
    return {
      entityType: 'member',
      editCardItems: [
        { title: 'SETTINGS' },
        { title: 'PROJECT_CONFIG' },
        { title: 'HISTORY', disabled: () => { return !this.isEditForm } }
      ],
      activeTab: 0,
      loadingMember: false,
      processingMember: false,
      newFeatures: [],
      newFeaturesLoading: false,
      dspSettingsLoading: false
    }
  },
  methods: {
    closeNewMember () {
      this.dialog = false
    },
    /**
     * @param memberSetting {MemberSettingModel}
     */
    async saveMember (memberSetting) {
      this.processingMember = true
      const options = {
        prod_authorize_external_segments: memberSetting.prodAuthorizeExternalSegments,
        prod_has_segment_fee_available: memberSetting.prodHasSegmentFeeAvailable,
        new_feature_references: this.newFeatures.filter(feature => feature.updated === true).map(feature => feature.getAsApiArgs(true)),
        dsp: memberSetting.member.dsp
      }
      let result = null

      if (this.isEditForm) {
        options.id = memberSetting.member.id
        result = await this.$store.dispatch('updateMember', options)
      } else {
        console.warn('Create member not implemented')
      }

      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when process')
        this.$store.commit('setErrorMessageWithResponse', result)
        const barConfig = {
          type: 'error',
          message: 'Error on update or create member'
        }
        this.$store.commit('setSnackBarConfig', barConfig)
        this.$store.commit('setActiveSnackBar', true)
      } else {
        console.warn('Process success')
        const barConfig = {
          type: 'success',
          message: 'Success !'
        }
        this.$store.commit('setSnackBarConfig', barConfig)
        this.$store.commit('setActiveSnackBar', true)
      }

      this.$emit('refresh')
      this.closeNewMember()
      this.processingMember = false
    },
    async getNewFeatures () {
      this.newFeaturesLoading = true
      const result = await this.$apiCaller.getNewFeatureReferencesForEntityWithDsp(this.currentEntity.id, 'member', this.currentEntity.member.dsp)
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when calling getNewFeaturesReference')
        this.$store.commit('setErrorMessageWithResponse', result)
        this.newFeaturesLoading = false
      } else {
        result.data.forEach(feature => {
          this.newFeatures.push(new NewFeatureReferenceModel(feature))
        })
        this.newFeaturesLoading = false
      }
    },
    async getCurrentEntity (memberId) {
      this.loadingMember = true
      this.currentEntity = new MemberSettingModel()

      const result = await this.$apiCaller.getKeystoneMemberSetting(memberId)
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when calling member')
        this.$store.commit('setErrorMessageWithResponse', result)
        this.closeNewMember()
      } else {
        this.currentEntity = new MemberSettingModel(result.data[0])
        if (this.newFeatures == null || this.newFeatures.length === 0) {
          await this.getNewFeatures()
        }
      }
      this.loadingMember = false
    },
    async getDspSettings () {
      this.dspSettingsLoading = true
      if (!(this.currentEntity && this.currentEntity.member && this.currentEntity.member.externalId && this.currentEntity.member.dsp)) {
        return
      }
      const dsp = this.currentEntity.member.dsp
      const memberExternalId = this.currentEntity.member.externalId
      await this.getDspSettingsFunc(dsp, memberExternalId)
      this.dspSettingsLoading = false
    }
  },
  watch: {
    'currentEntity.member.externalId': {
      deep: true,
      immediate: true,
      handler: function (externalId) {
        if (externalId) {
          this.getDspSettings()
        } else {
          console.warn('No external id')
          this.dspSettings = null
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
