import { render, staticRenderFns } from "./template_creation_data_element_report.vue?vue&type=template&id=3767e1ea&scoped=true"
import script from "./template_creation_data_element_report.vue?vue&type=script&lang=ts"
export * from "./template_creation_data_element_report.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3767e1ea",
  null
  
)

export default component.exports